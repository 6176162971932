<template>
       <div class="row">
      {{afiniaa1com}}
      </div>
    <div class="row">
    <div class="col-12">
      <div style="width: 100%;height: 200px">
      <table class="table table-bordered"  style="font-size: 110%">
          <thead style="color:#fff;background-color:white !important;">
            <tr class="p-0 m-0">
              <td><img src="../assets/AFINIA_CARIBE_MAR.png" transition="scale-transition" width="70"/></td>
              <td><img src="../assets/NEU_ENERGY.png" transition="scale-transition" width="70"/></td>
              <td><img src="../assets/ENEL_X.png" transition="scale-transition" width="80"/></td>
              <td><img src="../assets/VATIA.png"  transition="scale-transition" width="70"/></td>
            </tr>
          </thead>
          <tbody style="font-size: 85%">
            <tr>
              <td>
                <table class="table table-bordered"  style="font-size: 100%">
                  <tbody style="font-size: 70%">
                    <tr>
                      <td>MAYO</td>
                      <td>JUNIO</td>
                    </tr>
                    <tr>
                      <td>
                        <table class="table table-bordered"  style="font-size: 100%">
                          <tbody style="font-size: 120%;font-style:bold;">
                            <tr>
                              <td>NV</td>
                              <td>COM</td>
                              <td>CU+COT</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><input type="text" v-model="tarifasa[0][0][0]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[0][1][0]" size="2"></td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td><input type="text" v-model="tarifasa[0][0][1]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[0][1][1]" size="2"></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><input type="text" v-model="tarifasa[0][0][2]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[0][1][2]" size="2"></td>
                            </tr>                                                        
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table table-bordered"  style="font-size: 100%">
                          <tbody style="font-size: 120%">
                            <tr>
                              <td>NV</td>
                              <td>COM</td>
                              <td>CU+COT</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><input type="text" v-model="tarifasn[0][0][0]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[0][1][0]" size="2"></td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td><input type="text" v-model="tarifasn[0][0][1]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[0][1][1]" size="2"></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><input type="text" v-model="tarifasn[0][0][2]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[0][1][2]" size="2"></td>
                            </tr>                                                        
                          </tbody>
                        </table>
                      </td>                      
                    </tr>  
                  </tbody>
                </table>
              </td>

              <td>
                <table class="table table-bordered"  style="font-size: 100%">
                  <tbody style="font-size: 70%">
                    <tr>
                      <td>MAYO</td>
                      <td>JUNIO</td>
                    </tr>
                    <tr>
                      <td>
                        <table class="table table-bordered"  style="font-size: 100%">
                          <tbody style="font-size: 120%">
                            <tr>
                              <td>NV</td>
                              <td>COM</td>
                              <td>CU+COT</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><input type="text" v-model="tarifasa[1][0][0]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[1][1][0]" size="2"></td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td><input type="text" v-model="tarifasa[1][0][1]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[1][1][1]" size="2"></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><input type="text" v-model="tarifasa[1][0][2]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[1][1][2]" size="2"></td>
                            </tr>                      
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table table-bordered"  style="font-size: 100%">
                          <tbody style="font-size: 120%">
                            <tr>
                              <td>NV</td>
                              <td>COM</td>
                              <td>CU+COT</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><input type="text" v-model="tarifasn[1][0][0]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[1][1][0]" size="2"></td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td><input type="text" v-model="tarifasn[1][0][1]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[1][1][1]" size="2"></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><input type="text" v-model="tarifasn[1][0][2]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[1][1][2]" size="2"></td>
                            </tr>                          
                          </tbody>
                        </table>
                      </td>                      
                    </tr>  
                  </tbody>
                </table>
              </td>
              
              <td>
                <table class="table table-bordered"  style="font-size: 100%">
                  <tbody style="font-size: 70%">
                    <tr>
                      <td>MAYO</td>
                      <td>JUNIO</td>
                    </tr>
                    <tr>
                      <td>
                        <table class="table table-bordered"  style="font-size: 100%">
                          <tbody style="font-size: 120%">
                            <tr>
                              <td>NV</td>
                              <td>COM</td>
                              <td>CU+COT</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><input type="text" v-model="tarifasa[4][0][0]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[4][1][0]" size="2"></td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td><input type="text" v-model="tarifasa[4][0][1]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[4][1][1]" size="2"></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><input type="text" v-model="tarifasa[4][0][2]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[4][1][2]" size="2"></td>
                            </tr>  
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table table-bordered"  style="font-size: 100%">
                          <tbody style="font-size: 120%">
                            <tr>
                              <td>NV</td>
                              <td>COM</td>
                              <td>CU+COT</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><input type="text" v-model="tarifasn[5][0][0]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[5][1][0]" size="2"></td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td><input type="text" v-model="tarifasn[5][0][1]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[5][1][1]" size="2"></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><input type="text" v-model="tarifasn[5][0][2]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[5][1][2]" size="2"></td>
                            </tr>                            
                          </tbody>
                        </table>
                      </td>                      
                    </tr>  
                  </tbody>
                </table>
              </td>

              <td>
                <table class="table table-bordered"  style="font-size: 100%">
                  <tbody style="font-size: 70%">
                    <tr>
                      <td>MAYO</td>
                      <td>JUNIO</td>
                    </tr>
                    <tr>
                      <td>
                        <table class="table table-bordered"  style="font-size: 100%">
                          <tbody style="font-size: 120%">
                            <tr>
                              <td>NV</td>
                              <td>COM</td>
                              <td>CU+COT</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><input type="text" v-model="tarifasa[5][0][0]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[5][1][0]" size="2"></td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td><input type="text" v-model="tarifasa[5][0][1]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[5][1][1]" size="2"></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><input type="text" v-model="tarifasa[5][0][2]" size="2"></td>
                              <td><input type="text" v-model="tarifasa[5][1][2]" size="2"></td>
                            </tr>   
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table class="table table-bordered"  style="font-size: 100%">
                          <tbody style="font-size: 120%">
                            <tr>
                              <td>NV</td>
                              <td>COM</td>
                              <td>CU+COT</td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td><input type="text" v-model="tarifasn[5][0][0]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[5][1][0]" size="2"></td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td><input type="text" v-model="tarifasn[5][0][1]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[5][1][1]" size="2"></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td><input type="text" v-model="tarifasn[5][0][2]" size="2"></td>
                              <td><input type="text" v-model="tarifasn[5][1][2]" size="2"></td>
                            </tr>                           
                          </tbody>
                        </table>
                      </td>                      
                    </tr>  
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
     </div>
    </template>

  <script>
  require('@/css/sb-admin-2.min.css')
  require('@/vendor/fontawesome-free/css/all.min.css')
  import axios from 'axios'
  import CryptoJS from 'crypto-js'
  export default 
  {
      name: "HomeView",
      data: function () {
      return {
        afiniaa1com:'0',afiniaa11com:'0',afiniaa2com:'0',afiniaa1cu:'0',afiniaa11cu:'0',afiniaa2cu:'0',neun:'',neua:'',vatian:'',vatiaa:'',eneln:'',enela:'',
        tarifasa:[[['a','b','c'], ['d','e','f']],[['g','h', 'i'], ['j', 'k','l']],[['m','n', 'o'], ['p', 'q','r']],[['s','t', 'u'], ['v', 'w','w']],[['s','t', 'u'], ['v', 'w','w']],[['s','t', 'u'],['v', 'w','w']]],
        tarifasn:[[['a','b','c'], ['d','e','f']],[['g','h', 'i'], ['j', 'k','l']],[['m','n', 'o'], ['p', 'q','r']],[['s','t', 'u'], ['v', 'w','w']],[['s','t', 'u'], ['v', 'w','w']],[['s','t', 'u'],['v', 'w','w']]],
      };
    },
    mounted(){
      var fecha= new Date();
      var mes = fecha.getMonth();
      var yea = fecha.getFullYear();
      var factual=yea+"-"+mes.toString().padStart(2, '0')+"-01";
      let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"fecha":factual};
          axios.post("https://api.coenergia.app/api/rtarifas",json,)
          .then(data=>{
            console.log(data.data)
            this.afiniaa1com=(data.data[0]["C"])
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
    },
    methods: {

        }                         
      }
  </script>

<style>
table {
  border-collapse: separate;
  border:solid black 1px;
  border-radius:6px;
}

thead {
  position: sticky;
  top: 0;
  background-color: black;
}

input{
  border:none;
  background-color: transparent;
  outline: none
}
</style>