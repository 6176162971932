<template>
  <div class="row">
  <Encabezado @refrescar="refrescar"/>
</div>
<div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
  </div>
  <div class="row" style="padding:1% 1% 0% 1%;font-size:80%;">
    <div class="col-4 text-left">
      <input type="text" v-model="filtrarproyecto" placeholder="Proyecto" class="form-control">
          <ul style=" list-style:none" v-if="buscarproyecto.length" class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10">
            <li v-for="proyect in buscarproyecto" :key="proyect.proyecto" @click="Seleccionarproyecto(proyect.proyecto)" class="cursor-pointer hover:bg-gray-100 p-1" style="font-size:85%;">{{ proyect.proyecto }}</li>
          </ul><hr>
            <p v-if="prosel"><span>{{ prosel }}</span></p>
    </div>
    <div class="col-4 text-left">
        <input type="text" v-model="filtrarinsumo" placeholder="Insumo" class="form-control">
          <ul style=" list-style:none" v-if="buscarinsumo.length" class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10">
            <li v-for="insum in buscarinsumo" :key="insum.insumo" @click="Seleccionarinsumo(insum.insumo)" class="cursor-pointer hover:bg-gray-100 p-1" style="font-size:85%;">{{ insum.insumo }}</li>
          </ul><hr>
            <p v-if="inssel"><span>{{ inssel }}</span></p>
    </div>
    <div class="col-1 text-left">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" v-model="mio" @change="filtrar()" role="switch" id="flexSwitchCheckDefault">
        <label class="form-check-label" for="flexSwitchCheckDefault">Solo mios</label>
      </div>
    </div>
    <div class="col-1">
      <br><br><br>
      <input type="number" v-model="cantidad" class="form-control mb-2" placeholder="Cantidad">
    </div>
    <div class="col-1">
      <br><br><br>
      <button class="btn btn-success btn-login" @click="mensaje()">Agregar</button>
    </div>                  
  </div>  
  <div class="row" style="padding:1% 1% 1% 1%">
  <div class="col-12">
    <div style="width: 100%;height: 350px;overflow-y:auto;overflow-x:hidden;">
    <table class="table table-striped"  style="font-size: 90%">
        <thead style="color:#fff;background-color: gray  !important;;">
          <tr class="p-0 m-0"><td>Insumo</td><td>UDM/Proveedor</td><td>Cantidad</td><td>Editar</td></tr>
        </thead>
        <tbody>
          <tr v-for="itemsj in listado" :key="itemsj" :v-model="itemsj">
            <td>{{itemsj.insumo}}</td><td>{{itemsj.udm}}</td><td>{{itemsj.cantidad}}</td><td @click="edit(itemsj)"><i class="fas fa-fw fa-edit" style="font-size: 1.3rem; color: black;"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
  <Teleport to="body">
    <modal :show="showmodal" @close="showmodal = false">
      <template #body>
        <h6 class="card-title" style="text-align:center">{{this.msj}}</h6><hr>
        <div class="row mb-1">
                <div class="col-6"><a @click="showmodal=0" class="btn btn-success">Cancelar</a></div>
                <div class="col-6"><a @click="agregar()" class="btn btn-success">Aceptar </a></div>
              </div>
      </template>
    </modal>
  </Teleport>
  <Teleport to="body">
    <modal :show="editar" @close="showModal = false">
      <template #body>
        <div class="row mt-1 ml-1 text-left">
          <div class="col-4">
            <h4>{{this.insumo.insumo}}</h4>
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-outline-danger"  @click="borrar(this.insumo.id,this.insumo.cantidad,this.insumo.insumo,this.insumo.proyecto)">Borrar</button>
          </div>
        </div>
        <div class="row mt-1 ml-1 text-left">
          <h6>{{this.insumo.udm}}</h6>
        </div>
          <input type="text"  v-model="this.insumo.cantidad"  class="form-control">
        <hr>
        <div class="row mt-1 ml-1 text-left">
          <div class="col-6">
            <button type="button" class="btn btn-outline-success"  @click="salvar(this.insumo.id,this.insumo.cantidad,this.insumo.insumo)">Guardar</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary"  @click="this.editar=0">Cancelar</button>
          </div>    
        </div>
      </template>
    </modal>
  </Teleport>
</template>

<script>
require('@/css/sb-admin-2.min.css')
require('@/vendor/fontawesome-free/css/all.min.css')
import Encabezado from '@/components/Encabezado.vue';
import axios from 'axios'
import CryptoJS from 'crypto-js'
import Modal from '@/components/Modal.vue'
import {ref, computed} from 'vue'

export default {
name: "HomeView",
    components: {Encabezado,Modal},
data: function () {
    return {
      cantidad:null,showmodal:0,msj:'',editar:0,insumo:[],actual:0,mio:false
    };
  },
setup() {
  localStorage.switch=false
  let items
  let itemsi
  let itemsj
  let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
        let json={"api_token":token,};
        axios.post("https://apigestion.coenergia.app/pfvactivos",json,)
        .then(data=>{
            items=data.data
            axios.post("https://apigestion.coenergia.app/consultainsumos",json,)
            .then(data=>{itemsi=data.data})
            .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})})
        .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})
  let filtrarinsumo = ref('')
  let filtrarproyecto = ref('')
  const buscarinsumo = computed(() => {
    if (filtrarinsumo.value === '') {return []}
    let matches = 0
    return itemsi.filter(insum => {
      if (insum.insumo.toLowerCase().includes(filtrarinsumo.value.toLowerCase()) && matches < 10) {
        matches++
        return insum
      }
    })
  });
  const buscarproyecto = computed(() => {
    if (filtrarproyecto.value === '') {return []}
    let matches = 0
    return items.filter(proyect => {
      if (proyect.proyecto.toLowerCase().includes(filtrarproyecto.value.toLowerCase()) && matches < 10) {
        matches++
        return proyect
      }
    })
  });
  const Seleccionarinsumo = (insum) => {
    inssel.value = insum
    filtrarinsumo.value = ''
    let indice = itemsi.findIndex(posicion => posicion.insumo === insum);
    unidad.value=itemsi[indice].udm
    valor.value=itemsi[indice].valor
  }
  const Seleccionarproyecto = (proyect) => {
    prosel.value = proyect
    filtrarproyecto.value = ''
    listado.value=''
    let usr='todos'
    let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
    let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
    if (localStorage.switch=='true'){usr=usuario}
      let json={"api_token":token,"proyecto":proyect,"usuario":usr};
      console.log(json)
        axios.post("https://apigestion.coenergia.app/cargarinsumos",json,)
        .then(data=>{
          console.log(data.data)
              itemsj=data.data
              listado.value=itemsj
                          })
                .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})
  }
  let inssel = ref('')
  let prosel = ref('')
  let unidad = ref('')
  let valor = ref('')
  let listado=ref('')
  return {
    filtrarinsumo,buscarinsumo,Seleccionarinsumo,inssel,filtrarproyecto,buscarproyecto,Seleccionarproyecto,prosel,unidad,valor,listado
  }
},
methods: {
  seleccion()
    {
      let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
      let json={"api_token":token,"proyecto":this.proyectos};
        axios.post("https://apigestion.coenergia.app/cargarinsumos",json,)
        .then(data=>{
              this.listado.value=data.data
                          })
                .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})
    },
    mensaje()
    {
      if(this.prosel.length>0 && this.inssel.length>0 && this.cantidad>0)
        {
          this.msj="Desea agregar " +this.cantidad +" "+this.unidad.toLowerCase()+" de "+this.inssel
          this.showmodal=1
        }
      else
        {
          alert('Faltan datos')
        }
    },
    agregar()
      {
            this.showmodal=0
            let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
            let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
            let json={"api_token":token,"proyecto":this.prosel,"insumo":this.inssel,"udm":this.unidad,"cantidad":this.cantidad,"valor":this.valor,"estado":"Ingenieria","usuario":usuario};
            console.log(json)
            axios.post("https://apigestion.coenergia.app/agregarinsumos",json,)
            .then(data=>{
              this.cantidad=0
              this.insumos=''
              this.listado=data.data
              this.inssel=''
              this.cantidad=null
            })
                .catch(error => {
                      console.log(error);
                      this.msj='Error de registro';
                      this.enable=1;
                      }
                      )

      },
      refrescar(ruta)
      {
        this.$router.push(ruta);
      },
      edit(ins)
      {
        if(ins.estado=="Ingenieria")
        {
          this.actual=ins.cantidad
          this.insumo=ins
          this.editar=1
        }
        else
        {
          alert("No se puede editar porque ya fue alistado") 
        }
      },
      salvar(linea,qty,ins)
      {
            this.actual=this.actual-qty
            if (this.actual!=0)
            {
            let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
            let json={"api_token":token,"linea":linea,"cantidad":qty,"insumo":ins,"diferencia":this.actual};
            axios.post("https://apigestion.coenergia.app/editarinsumos",json,)
            .then(data=>{
              if (data.data=="ok")
              {
                this.editar=0
              }
            })
                .catch(error => {
                      console.log(error);
                      this.msj='Error de registro';
                      this.enable=1;
                      }
                      )
            }
            else
            {
              this.editar=0
            }
      },
      borrar(linea,qty,ins,proy)
      {
            let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
            let json={"api_token":token,"linea":linea,"cantidad":(this.actual),"insumo":ins,"proyecto":proy};
            axios.post("https://apigestion.coenergia.app/borrarinsumos",json,)
            .then(data=>{
              console.log(data.data)
              this.itemsi=data.data
              this.listado.value=this.itemsj
              this.Seleccionarproyecto(proy)
              this.editar=0
            })
                .catch(error => {
                      console.log(error);
                      this.msj='Error de registro';
                      this.enable=1;
                      }
                      )
      },
      filtrar()
      {
        if (this.mio==false)
        {
          localStorage.switch=false
        }
        else
        {
          localStorage.switch=true
        }
        this.Seleccionarproyecto(this.prosel)
      }                      

    }

}
</script>