<template>
  <div class="row">
    <Encabezado @refrescar="refrescar"/>
  </div>
    <div class="row">
    </div>
    <div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
    </div>
    <div class="row" style="padding:1% 1% 1% 1%">
    <div class="col-12">
      <div style="width: 100%;height: 350px;overflow-y:auto;overflow-x:hidden;">
      <table class="table table-bordered"  style="font-size: 130%">
          <thead style="color:#fff;background-color: gray:  !important;;">
            <tr class="p-0 m-0"><td>Insumo</td><td>UDM</td><td>Cantidad</td></tr>
          </thead>
          <tbody style="font-size: 100%">
            <tr v-for="items in this.items" :key="items" @click="recibir(items)">
              <td>{{items.insumo}}</td><td>{{items.udm}}</td><td>{{items.cantidad}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
    <Teleport to="body">
      <modal :show="showmodal" @close="showmodal = false">
        <template #body>
          <h5 class="card-title" style="text-align:center">{{this.msj}}</h5><br>
            <input type="number" v-model="qty" class="form-control" style="font-size: 160%"><br>
         <div class="row mb-1">
                  <div class="col-6" style="font-size: 160%"><a @click="showmodal=0" class="btn btn-success">Cancelar</a></div>
                  <div class="col-6" style="font-size: 160%"><a @click="enviar()" class="btn btn-success">Aceptar </a></div>
                </div>
        </template>
      </modal>
    </Teleport>
    </template>

  <script>
  require('@/css/sb-admin-2.min.css')
  require('@/vendor/fontawesome-free/css/all.min.css')
  import Encabezado from '@/components/Encabezado.vue';
  import axios from 'axios'
  import CryptoJS from 'crypto-js'
  import Modal from '@/components/Modal.vue'
  export default 
  {
      name: "HomeView",
      components: {Encabezado,Modal},
      data: function () {
      return {
        proyectos:'',insumos:'',cantidad:0,showmodal:0,items:[],itemsi:[],itemsj:[],msj:'',linea:[],qty:0,lider:''
      };
    },
    beforeMount() {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,"usuario":usuario};
          axios.post("https://apigestion.coenergia.app/recibirmercancia",json,)
          .then(data=>{
                this.items=data.data
              })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )

    },
    methods: {
      mensaje()
      {
        if(this.proyectos.length>0 && this.insumos.insumo.length>0 && this.cantidad>0)
          {
        this.msj="Desea agregar " +this.cantidad +" "+this.insumos.udm+" de "+this.insumos.insumo
        this.showmodal=1
          }
        else
          {
            alert('falta')
          }
      },
        recibir(detalle)
        {
          this.qty=0
          this.linea=detalle
          this.msj='Indique la cantidad recibida de ' +this.linea.insumo
          this.showmodal=1
        },
        enviar()
        {
              console.log(this.linea)
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"id":this.linea.id,"esperada":this.linea.cantidad,"recibida":this.qty,"usuario":usuario};
              axios.post("https://apigestion.coenergia.app/recibircampo",json,)
              .then(data=>{
                this.items=data.data
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
          this.showmodal=0
        },                 
        refrescar(ruta)
        {
          this.$router.push(ruta);
        },

 
      }
      }
  </script>

<style>
table {
  border-collapse: separate;
  border:solid black 1px;
  border-radius:6px;
}

thead {
  position: sticky;
  top: 0;
  background-color: black;
}
</style>