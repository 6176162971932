<template>
  <div class="row">
    <Encabezado @refrescar="refrescar"/>
  </div>
    <div class="row">
    </div>
    <div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
    </div>
    <div class="row  mb-1 mt-1 mr-1">
      <div class="col-5"></div>
      <div class="col-7">
          <div class="row">
            <div class="col-2">Lider</div>
            <div class="col-10">
            <select class="form-control" v-model="lider" @change="seleccion()">
              <option v-for="item in this.items" :key="item" :value="item.nombre">{{ item.nombre }}</option>
            </select>
          </div>
          </div>  
        </div>
      </div>
    <div class="row" style="padding:1% 1% 1% 1%">
    <div class="col-4">
      <div style="width: 100%;height: 350px;overflow-y:auto;overflow-x:hidden;">
      <table class="table table-bordered"  style="font-size: 85%">
          <thead style="color:#fff;background-color: gray  !important;">
            <tr class="p-0 m-0"><td>cliente</td><td>pendiente</td></tr>
          </thead>
          <tbody style="font-size: 85%">
            <tr v-for="itemsi in this.itemsi" :key="itemsi" @click="solicitar(itemsi.proyecto)">
              <td :style="{backgroundColor:itemsi.colorplanta}">{{itemsi.proyecto}}</td><td>{{itemsi.pendientes}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-8">
      <div style="width: 100%;height: 350px;overflow-y:auto;overflow-x:hidden;">
      <table class="table table-bordered"  style="font-size: 85%">
          <thead style="color:#fff;background-color: gray  !important;">
            <tr class="p-0 m-0"><td>Solicita</td><td>Insumo</td><td>UDM</td><td>Cantidad</td></tr>
          </thead>
          <tbody style="font-size: 85%">
            <tr v-for="itemsj in this.itemsj" :key="itemsj" @click="despachar(itemsj)">
              <td>{{itemsj.usuario}}</td><td>{{itemsj.insumo}}</td><td>{{itemsj.udm}}</td><td>{{itemsj.cantidad}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
    <Teleport to="body">
      <modal :show="showmodal" @close="showmodal = false">
        <template #body>
          <h6 class="card-title" style="text-align:center">{{this.msj}}</h6><br>
            <input type="number" v-model="qty" class="form-control"><br>
         <div class="row mb-1">
                  <div class="col-6"><a @click="showmodal=0" class="btn btn-success">Cancelar</a></div>
                  <div class="col-6"><a @click="enviar()" class="btn btn-success">Aceptar </a></div>
                </div>
        </template>
      </modal>
    </Teleport>
    </template>

  <script>
  require('@/css/sb-admin-2.min.css')
  require('@/vendor/fontawesome-free/css/all.min.css')
  import Encabezado from '@/components/Encabezado.vue';
  import axios from 'axios'
  import CryptoJS from 'crypto-js'
  import Modal from '@/components/Modal.vue'
  export default 
  {
      name: "HomeView",
      components: {Encabezado,Modal},
      data: function () {
      return {
        proyectos:'',insumos:'',cantidad:null,showmodal:0,items:[],itemsi:[],itemsj:[],msj:'',linea:[],qty:null,lider:''
      };
    },
    beforeMount() {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,};
          axios.post("https://apigestion.coenergia.app/lideres",json,)
          .then(data=>{
                this.items=data.data
                axios.post("https://apigestion.coenergia.app/pendientesenviar",json,)
                .then(data=>{
                  console.log(data.data)
                  this.itemsi=data.data
                              })
                    .catch(error => {
                          console.log(error);
                          this.msj='Error de registro';
                          this.enable=1;
                          }
                          )
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
          
    },
    methods: {
      mensaje()
      {
        if(this.proyectos.length>0 && this.insumos.insumo.length>0 && this.cantidad>0)
          {
        this.msj="Desea agregar " +this.cantidad +" "+this.insumos.udm+" de "+this.insumos.insumo
        this.showmodal=1
          }
        else
          {
            alert('falta')
          }
      },
      solicitar(proyecto)
        {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,"proyecto":proyecto};
          axios.post("https://apigestion.coenergia.app/cargarproyecto",json,)
          .then(data=>{
            this.itemsj=data.data
            this.colores(proyecto)
            })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
        },
        colores(proyec)
        {
          for (var t =0 ; t < this.itemsi.length; t++) 
            {
            if(this.itemsi[t]['proyecto']==proyec){this.itemsi[t]['colorplanta']='red'}else{this.itemsi[t]['colorplanta']='#CBC8C7'}
            }
          console.log(this.itemsi)
        },
        despachar(detalle)
        {
          this.qty=null
          this.linea=detalle
          this.msj='Indique la cantidad a enviar de ' +this.linea.insumo
          this.showmodal=1
        },
        enviar()
        {
          if (this.lider.length>5)
          {
          if(this.qty>0)
          { 
            if(this.qty>this.linea.cantidad)
            {
              alert('No puede enviar mayor cantidad')
            }
            else
            {
              console.log(this.linea)
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"proyecto":this.linea.proyecto,"id":this.linea.id,"cantidad":this.qty,"usuario":usuario,"lider":this.lider};
              axios.post("https://apigestion.coenergia.app/procesarenvio",json,)
              .then(data=>{
              this.itemsj=data.data
              if (this.itemsj.length==0)
                {
                  let json={"api_token":token};
                  axios.post("https://apigestion.coenergia.app/pendientesenviar",json,)
                  .then(data=>{
                    console.log(data.data)
                    this.itemsi=data.data
                                })
                      .catch(error => {
                            console.log(error);
                            this.msj='Error de registro';
                            this.enable=1;
                            }
                            )
                }
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
            }
          }
          else
          {
            alert('No puede enviar 0')
          }
        }
        else
        {
          alert('Debe seleccionar un lider')       
        }
          this.showmodal=0
        },                 
        refrescar(ruta)
        {
          this.$router.push(ruta);
        },
      }
      }
  </script>

<style>
table {
  border-collapse: separate;
  border:solid black 1px;
  border-radius:6px;
}

thead {
  position: sticky;
  top: 0;
  background-color: black;
}
</style>