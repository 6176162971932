<template>
  <div>
    <nav class="navbar bg-white">
      <img src="../assets/logo.png" class="ml-3" transition="scale-transition" width="100"/>
      <i class="fas fa-fw fa-list" style="font-size: 1.3rem; color: black;" data-toggle="modal" data-target="#menu"></i>
      <a class="nav-link" data-bs-toggle="dropdown" style="color:black;padding: 0px 10px 0px 0px;font-size:1rem" >{{this.usuario}}</a>
    </nav>
  </div>
  <div class="modal fade" id="menu" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content mr-3">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLabel">Menu principal</h6>
            <button ref="cerrar" class="close" type="button" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"></span>
                </button>
                </div>
                <div class="row m-2">
                    <button v-show="this.registrar" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/registrar')"><i style="color:white;"></i>Registrar Proyecto</button>
                    <button v-show="this.ingenieria" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/ingenieria')"><i style="color:white;"></i>Planeacion</button>
                    <button v-show="this.proyectos" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/proyectos')"><i style="color:white;"></i>Proyectos</button>
                    <button v-show="this.bodega" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/bodega')"><i style="color:white;"></i>Bodega</button>
                    <button v-show="this.traslado" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/trasladobodega')"><i style="color:white;"></i>Traslado</button>
                    <button v-show="this.campo" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/recibocampo')"><i style="color:white;"></i>Recibo mercancia</button>
                    <button v-show="this.imprevistos" class="btn btn-outline-success mb-1" type="button"  @click="funcion('/imprevistos')"><i style="color:white;"></i>Servicios</button>
                    <button class="btn btn-outline-danger mb-1" type="button"  @click="funcion('/emergencia')"><i style="color:white;"></i> EMERGENCIAS</button>
                    <button class="btn btn-outline-success mb-1" type="button"  @click="salir()"><i style="color:white;"></i> Salir</button>
                  </div>
              </div>
        </div>
    </div>  
</template>

<script>
import CryptoJS from 'crypto-js'
import axios from 'axios'
export default {
  name: 'EncabezadoVue',
  components: {},
  data: function () {
    return {
      usuario:null,
      registrar:0,
      ingenieria:0,
      proyectos:0,
      bodega:0,
      traslado:0,
      perfil:0,
      campo:0,
      imprevistos:0
    };
  },
  beforeMount() {
    this.usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
    this.perfil=(CryptoJS.AES.decrypt(localStorage.d3,"G3st10n").toString(CryptoJS.enc.Utf8));
    switch (this.perfil) {
      case '1':
      this.registrar=1;this.ingenieria=1;this.proyectos=1;this.bodega=1;this.traslado=1;this.campo=1;this.imprevistos=1;
      break;
      case '2':
      this.registrar=0;this.ingenieria=0;this.proyectos=0;this.bodega=0;this.traslado=0;this.campo=0;this.imprevistos=0;
      break;      
      case '3':
      this.registrar=0;this.ingenieria=0;this.proyectos=0;this.bodega=0;this.traslado=0;this.campo=0;this.imprevistos=0;
      break;      
      case '4':
      this.registrar=0;this.ingenieria=0;this.proyectos=0;this.bodega=1;this.traslado=0;this.campo=0;this.imprevistos=1;
      break;      
      case '5':
      this.registrar=0;this.ingenieria=0;this.proyectos=0;this.bodega=1;this.traslado=1;this.campo=0;this.imprevistos=1;
      break;   
      case '6':
      this.registrar=0;this.ingenieria=0;this.proyectos=0;this.bodega=0;this.traslado=0;this.campo=0;this.imprevistos=0;
      break;   
      default:
    }

        },
  methods: {
    funcion(opcion){
      this.$refs.cerrar.click();
      this.$emit("refrescar",opcion)
    },
    salir()
    {
      this.$refs.cerrar.click();
      let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
      let json={"api_token":token,};
      axios.post(window.url+"/logout",json)
        .then(data=>{
        localStorage.removeItem('d1');
        localStorage.removeItem('d2');
        this.$router.push('/');
        })
        .catch(error => {
        console.log(error)
        }
      )
    }
}
}
</script>
