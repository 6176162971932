<template>
  <div class="row">
    <Encabezado @refrescar="refrescar"/>
  </div>
    <div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
    </div>
    <div class="row" style="padding:1% 1% 1% 1%;font-size:80%;">
      <div class="col-6">
        <form>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-left">Proyecto</label>
              <div class="col-sm-9"><input type="text" v-model="proyecto" class="form-control"></div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-left">Contrato</label>
              <div class="col-sm-9"><input type="text" v-model="contrato" class="form-control"></div>
          </div>          
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-left">Direccion</label>
              <div class="col-sm-9"><input type="text"  v-model="direccion"  class="form-control"></div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-left">Capacidad(kW)</label>
              <div class="col-sm-9"><input type="text"  v-model="capacidad"  class="form-control"></div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-left">Dias de ejecución</label>
              <div class="col-sm-9"><input type="text"  v-model="tiempo"  class="form-control"></div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label text-left">Fecha</label>
              <div class="col-sm-9"><input type="date"  v-model="fecha"  class="form-control"></div>
          </div><hr>
          <div class="form-check form-check-inline" style="align-items: right;">
            <input class="form-check-input" type="checkbox"  v-model="planta">
            <label class="form-check-label" for="inlineCheckbox1">PLANTA</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox"  v-model="agpe">
            <label class="form-check-label" for="inlineCheckbox2">AGPE</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox"  v-model="comercializador">
            <label class="form-check-label" for="inlineCheckbox3">COMERCIALIZADOR</label>
          </div><hr> 
          <div class="col-sm-3">
            <button class="btn btn-success btn-login" @click="ingresar()">Ingresar</button>
          </div>                             
        </form>
      </div>      
      <div class="col-6 mt-1">
        <div style="width: 100%;height: 400px;overflow-y:auto;overflow-x:hidden;">
          <table class="table table-bordered"  style="font-size: 80%">
            <thead style="color:#fff;background-color: gray  !important;;">
              <tr class="p-0 m-0"><td>Proyecto</td><td>Capacidad</td></tr>
            </thead>
            <tbody>
              <tr v-for="items in this.items" :key="items">
                <td>{{items.proyecto}}</td><td>{{items.capacidad}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </template>

  <script>
  require('@/css/sb-admin-2.min.css')
  require('@/vendor/fontawesome-free/css/all.min.css')
  import Encabezado from '@/components/Encabezado.vue';
  import axios from 'axios'
  import CryptoJS from 'crypto-js'
  import Modal from '@/components/Modal.vue'
  export default 
  {
      name: "HomeView",
      components: {Encabezado},
      data: function () {
      return {
        msj:'',showModal:0,showmsj:0,items:[],proyecto:'',contrato:'',capacidad:0,direccion:'',fecha:'',comercializador:'',agpe:'',planta:'',tiempo:0
      };
    },
    beforeMount() {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,};
          axios.post("https://apigestion.coenergia.app/consultarproyectosultimo",json,)
          .then(data=>{
            this.items=data.data
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
    },
    methods: {
      ingresar()
        {
          if(this.proyecto.length>0 && this.contrato.length>0 && this.direccion.length>0 && this.capacidad.length>0 && this.tiempo.length>0 && this.fecha.length>0)
          {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,"proyecto":this.proyecto,"contrato":this.contrato,"capacidad":this.capacidad,"dias":this.tiempo,"fechainicio":this.fecha,"direccion":this.direccion,"agpe":this.agpe,"comercializador":this.comercializador,"planta":this.planta};
          axios.post("https://apigestion.coenergia.app/ingresarproyectos",json)
          .then(data=>{
            this.showmsj=0
            this.items=data.data
            this.proyecto=''
            this.contrato=''
            this.capacidad=0
            this.tiempo=0
            this.direccion=''
            this.fechainicio=''
            this.comercializador=false
            this.agpe=false
            this.planta=false  
            this.fecha='' 
          })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
        }
        else
        {
          alert("Faltan datos")
        }
        },
        refrescar(ruta)
        {
          this.$router.push(ruta);
        },
      }
      }
  </script>

<style>
table {
  border-collapse: separate;
  border:solid black 1px;
  border-radius:6px;
}

thead {
  position: sticky;
  top: 0;
  background-color: black;
}
</style>