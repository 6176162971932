<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-6 col-lg-4 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div style="align-items: center;">
          <img src="../assets/logo.png" class="mt-3" width="100">
          </div>
          <div class="card-body p-4 p-sm-5">
            <div>
              <div class="form-floating mb-4">
                <input type="text" v-model="email" class="form-control">
                <label for="floatingInput">Usuario</label>
              </div>
              <div class="form-floating mb-3">
                <input type="password" v-model="password" class="form-control" autocomplete="on">
                <label for="floatingPassword">Contraseña</label>
              </div>
              <div v-show=this.mensaje class="alert alert-danger" role="alert">
                {{this.texto}}
              </div>               
              <div class="d-grid pt-5">
                <button class="btn btn-success btn-login" @click="login()">Ingresar</button>
              </div>
              <hr class="my-4">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
 import axios from 'axios'
 import CryptoJS from 'crypto-js'
 export default {
    name: "HomeView",
    data: () => ({email:'',password:'',mensaje:false,texto:''}),
    beforeMount() {this.email='',this.password=''},
    methods: {
        login() {
          if (this.email.length!=0 && this.password.length!=0)
            {
            let json={"usuario":this.email,"password":this.password};
            console.log(json)
            axios.post("https://apigestion.coenergia.app/login",json)
            .then(data=>{
              localStorage.d1=CryptoJS.AES.encrypt(data.data.token, "G3st10n").toString();
              localStorage.d2=CryptoJS.AES.encrypt(data.data.usuario, "G3st10n").toString();
              localStorage.d3=CryptoJS.AES.encrypt(data.data.perfil, "G3st10n").toString();
              if (data.data.perfil=='1')
              {
                this.$router.push('/proyectos');
              }
              if (data.data.perfil=='2')
              {
                this.$router.push('/proyectos');
              }
              if (data.data.perfil=='3')
              {
                this.$router.push('/ingenieria');
              }
              if (data.data.perfil=='4')
              {
                this.$router.push('/bodega');
              }
              if (data.data.perfil=='5')
              {
                this.$router.push('/bodega');
              }
              if (data.data.perfil=='6')
              {
                this.$router.push('/recibocampo');
              }

              console.log(data);
            })
                .catch(error => {
                this.texto = "Validar información"
                this.mensaje=true
                console.log(error)
                }
                )
              }
                else
                {
                this.texto='Falta información'
                this.mensaje=true
                }
        },
        limpiar: function(){
            this.mensaje=false
        },
    },
}
 </script>
 
