import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import "bootstrap/dist/css/bootstrap.min.css"
let app = createApp(App)
createApp(App).use(store).use(router).mount('#app')
app.config.globalProperties.window = window
window.url = 'https://apigestion.coenergia.app'
//window.url='http://192.168.1.86:8000'


