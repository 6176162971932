<template>
  <div class="row">
  <Encabezado @refrescar="refrescar"/>
</div>
<div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
  </div>
  <div class="row" style="padding:1% 1% 0% 1%;font-size:80%;">
    <div class="col-12 text-center">
      <input type="text" v-model="filtrarcliente" placeholder="cliente" @click="this.informacion=0" class="form-control">
          <ul v-if="buscarcliente.length" class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10">
            <li v-for="proyect in buscarcliente" :key="proyect.cliente" @click="Seleccionarcliente(proyect)" class="cursor-pointer hover:bg-gray-100 p-1" style="font-size:85%;">{{ proyect.cliente }}</li>
          </ul><hr>
          <p hidden="true" v-if="prosel">{{this.informacion=2}}</p>
    </div>
              
  </div>
  <div v-show="informacion">
      <img :src="`../assets/${prosel.operador}.png`" class="ml-3" transition="scale-transition" width="100"/>
      <div class="row" style="padding:1% 1% 1% 1%">
          <h4>{{prosel.telefono}}</h4>
      </div><hr>
      <div class="row" style="padding:1% 1% 1% 1%">
          <h4>{{prosel.cliente}}</h4>
      </div>
      <div class="row" style="padding:1% 1% 1% 1%">
          <h4>{{prosel.direccion}}</h4>
      </div>
      <div class="row" style="padding:1% 1% 1% 1%">
          <h4>CIRCUITO {{prosel.circuito}}</h4>
      </div>
      <div class="row" style="padding:1% 1% 1% 1%">
          <h4>ID:{{prosel.numeroid}}</h4>
      </div>    
</div>    
</template>

<script>
require('@/css/sb-admin-2.min.css')
require('@/vendor/fontawesome-free/css/all.min.css')
import Encabezado from '@/components/Encabezado.vue';
import axios from 'axios'
import CryptoJS from 'crypto-js'
import Modal from '@/components/Modal.vue'
import {ref, computed} from 'vue'

export default {
name: "HomeView",
    components: {Encabezado},
data: function () {
    return {
      cantidad:null,showmodal:0,msj:'',informacion:0
    };
  },
setup() {
  let items
  let itemsi
  let itemsj
  let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
        let json={"api_token":token,};
        axios.post("https://apigestion.coenergia.app/clientes",json,)
        .then(data=>{
            items=data.data
            console.log(items)
            axios.post("https://apigestion.coenergia.app/consultainsumos",json,)
            .then(data=>{itemsi=data.data})
            .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})})
        .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})
  let filtrarinsumo = ref('')
  let filtrarcliente = ref('')
  const buscarinsumo = computed(() => {
    if (filtrarinsumo.value === '') {return []}
    let matches = 0
    return itemsi.filter(insum => {
      if (insum.insumo.toLowerCase().includes(filtrarinsumo.value.toLowerCase()) && matches < 10) {
        matches++
        return insum
      }
    })
  });
  const buscarcliente = computed(() => {
    if (filtrarcliente.value === '') {return []}
    let matches = 0
    return items.filter(proyect => {
      if (proyect.cliente.toLowerCase().includes(filtrarcliente.value.toLowerCase()) && matches < 10) {
        matches++
        return proyect
      }
    })
  });
  const Seleccionarcliente = (proyect) => {
    prosel.value = proyect
    filtrarcliente.value = ''
    listado.value=''
    let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
      let json={"api_token":token,"cliente":proyect};
      console.log(json)
        axios.post("https://apigestion.coenergia.app/cargarinsumos",json,)
        .then(data=>{
              itemsj=data.data
              listado.value=itemsj
                          })
                .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})
  }
  let inssel = ref('')
  let prosel = ref('')
  let unidad = ref('')
  let valor = ref('')
  let listado=ref('')
  return {
    inssel,filtrarcliente,buscarcliente,Seleccionarcliente,prosel,unidad,valor,listado
  }
},
methods: {
    seleccion()
    {
      let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
      let json={"api_token":token,"cliente":this.clientes};
        axios.post("https://apigestion.coenergia.app/cargarinsumos",json,)
        .then(data=>{
              this.listado.value=data.data
                          })
                .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})
    },
    mensaje()
    {
      if(this.prosel.length>0 && this.inssel.length>0 && this.cantidad>0)
        {
          this.msj="Desea agregar " +this.cantidad +" "+this.unidad.toLowerCase()+" de "+this.inssel
          this.showmodal=1
        }
      else
        {
          alert('Faltan datos')
        }
    },
    agregar()
      {
            this.showmodal=0
            let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
            let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
            let json={"api_token":token,"cliente":this.prosel,"insumo":this.inssel,"udm":this.unidad,"cantidad":this.cantidad,"valor":this.valor,"estado":"Ingenieria","usuario":usuario};
            console.log(json)
            axios.post("https://apigestion.coenergia.app/agregarinsumos",json,)
            .then(data=>{
              this.cantidad=0
              this.insumos=''
              this.listado=data.data
              this.inssel=''
              this.cantidad=null
            })
                .catch(error => {
                      console.log(error);
                      this.msj='Error de registro';
                      this.enable=1;
                      }
                      )

      },
      refrescar(ruta)
      {
        this.$router.push(ruta);
      },

    }

}
</script>