<template>
  <div class="row">
    <Encabezado @refrescar="refrescar"/>
  </div>
    <div class="row">
    </div>
    <div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
    </div>
    <div class="row" v-show="!this.costo">
    <div class="row  mb-1 mt-1 mr-1">
      <div class="col-5"></div>
      <div class="col-7">
          <div class="row">
            <div class="col-3">Estado</div>
            <div class="col-9">
            <select class="form-control" v-model="flt" @change="consultar()">
              <option v-for="item in this.estados" :key="item" :value="item">{{ item }}</option>
            </select>
          </div>
          </div>  
        </div>
      </div>
    </div>
    <div class="row" v-show="!this.costo">
    <div v-show="!this.pantalla" class="row" style="padding:0% 1% 1% 1%">
    <div class="col-12">
      <div style="width: 100%;height: 600px;overflow-y:auto;overflow-x:hidden;color: black;">
      <table class="table table-striped"  style="font-size: 100%">
          <thead style="color:#fff;background-color:black  !important;">
            <tr class="p-0 m-0"><td>Proyecto</td><td>Capacidad</td><td>Estado</td><td>Costos</td></tr>
          </thead>
          <tbody>
            <tr v-for="items in this.items" :key="items">
              <td>{{items.proyecto}}</td><td>{{items.capacidad}}</td><td :style="{backgroundColor:items.colorplanta,color:items.colortexto}">{{items.tplanta}}</td><td @click="detalle(items)"><i class="fas fa-fw fa-eye" style="font-size: 1.3rem; color: black;"></i></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
    <div v-show="this.pantalla" class="row" style="padding:1% 0% 1% 4%">
    <div class="col-12">
      <div style="width: 100%;height:600px;overflow-y:auto;overflow-x:hidden;color: white;">
      <table class="table table-striped"  style="font-size: 80%">
          <thead style="color:#fff;background-color:gray  !important;">
            <tr class="p-0 m-0"><td>Proyecto</td><td>Capacidad</td><td>Estado</td><td>Costos</td></tr>
          </thead>
          <tbody>
            <tr v-for="items in this.items" :key="items">
              <td>{{items.proyecto}}</td><td>{{items.capacidad}}</td><td :style="{backgroundColor:items.colorplanta,color:items.colortexto}">{{items.tplanta}}</td><td @click="detalle(items)"><i class="fas fa-fw fa-eye" style="font-size: 1.3rem; color: black;"></i></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>    
    </div>    
    <div class="row" v-show="this.costo">
    <div class="row" style="padding:1% 1% 1% 1%">
    <div class="col-12">
      <div class="row mt-1 ml-1 text-left">
        <h6>{{this.proyecto}}</h6>
      </div><hr>
      <div class="row mt-1 ml-1 text-left">
        <div class="col-9">
          <h2>{{this.valor}}</h2>
        </div>
        <div class="col-3">
          <i @click="this.costo=0" class="fas fa-fw fa-undo" style="font-size: 1.4rem; color: green;"></i>
        </div>
      </div>
      <div style="width: 100%;height:500px;overflow-y:auto;overflow-x:hidden;color: #000;">
      <table class="table table-striped"  style="font-size: 90%">
          <thead style="color:#fff;background-color: gray  !important;">
            <tr class="p-0 m-0"><td>Insumo</td><td>Cantidad</td><td>Valor</td><td>Total</td></tr>
          </thead>
          <tbody>
            <tr v-for="itemsi in this.itemsi" :key="itemsi">
              <td>{{itemsi.insumo}}</td><td>{{itemsi.cantidad}}</td><td>{{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD",minimumFractionDigits: 0}).format(itemsi.valor)}}</td><td>{{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD",minimumFractionDigits: 0}).format(itemsi.valortotal)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
    </div>
    <Teleport to="body">
      <modal :show="showmsj" @close="showModal = false">
        <template #body>
      
          <div class="row mb-1" style="text-align:center">{{this.seleccion}}
          </div><hr>
          <div class="row mb-1" style="text-align:center">
                  <a @click="cambiar()" class="btn btn-outline-dark">Cambiar a: {{ this.estado }}</a>
          </div><hr>
          <div class="btn-group mr-2" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-success"  @click="showmsj=0">cerrar</button>
          </div>
        </template>
      </modal>
    </Teleport>

    </template>

  <script>
  require('@/css/sb-admin-2.min.css')
  require('@/vendor/fontawesome-free/css/all.min.css')
  import Encabezado from '@/components/Encabezado.vue';
  import axios from 'axios'
  import CryptoJS from 'crypto-js'
  import Modal from '@/components/Modal.vue'
import { faArrowsLeftRight } from '@fortawesome/free-solid-svg-icons';
  export default 
  {
      name: "HomeView",
      components: {Encabezado,Modal},
      data: function () {
      return {
        estados:["Todos","Ingresado","En proceso","Cerrado"],flt:'',
        msj:'',showModal:0,showmsj:0,items:[],itemsi:[],costo:0,proyecto:'',valor:0,pantalla:0,seleccion:'',estado:'',funcion:'',
        arrplanta:['INGRESADO','EN PROCESO','CERRADO'],
        arragpe:['INGRESADO','CARPETA SUBIDA','PENDIENTE RADICADO','PENDIENTE CARTA CONFORMIDAD','PENDIENTE VISITA','PENDIENTE CARTA DE VIABILIDAD','PENDIENTE INSTALACION MEDIDOR','REGISTRO','CERRADO'],
        arrcomercializador:['INGRESADO','PENDIENTE DOCUMENTOS','PENDIENTE PREVISITA','PENDIENTE CAMBIO EQUIPOS','CERRADO'],
      };
    },
    beforeMount() {
      if (screen.width>screen.height)
      {
        this.pantalla=0
      } 
      else
      {
        this.pantalla=1
      }
    },
    mounted(){
      let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,};
          axios.post("https://apigestion.coenergia.app/consultarproyectos",json,)
          .then(data=>{
            this.colores(data.data)
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
    },
    methods: {
      consultar()
        {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,"filtro":this.flt};
          console.log(json)
          axios.post("https://apigestion.coenergia.app/filtroproyectos",json)
          .then(data=>{
            this.showmsj=0
              this.colores(data.data)
            })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
        },
        refrescar(ruta)
        {
          this.$router.push(ruta);
        },
        colores(datos)
        {
          this.items=datos
          const milidia = 86400000
          const hoy = new Date()
          const dia=hoy.getDate().toString().padStart(2, '0')
          const mes=(hoy.getMonth()+1).toString().padStart(2, '0')
          const year=(hoy.getFullYear()).toString()
          const fechahoy=year+'-'+mes+'-'+dia
          for (var t =0 ; t < this.items.length; t++) 
                {
                let separadias=this.items[t]['dias'].split('-')
                this.items[t]['diastotal']=separadias[0]
                this.items[t]['diasplanta']=separadias[1]
                this.items[t]['ahoy']=(new Date(fechahoy)-new Date(this.items[t]['fechainicio']))/milidia
                this.items[t]['resta']=separadias[1]-(new Date(fechahoy)-new Date(this.items[t]['fechainicio']))/milidia  
                this.items[t]['restap']=separadias[0]-(new Date(fechahoy)-new Date(this.items[t]['fechainicio']))/milidia  
                console.log(this.items[t]['restap'])
                if(this.items[t]['planta']=='CERRADO')
                {
                  this.items[t]['tplanta']="CERRADO";
                  this.items[t]['colorplanta']='black';
                  this.items[t]['colortexto']='white'
                }
                else
                {
                this.items[t]['tplanta']=this.items[t]['planta']+'  ['+this.items[t]['resta']+']';
                if(this.items[t]['resta']<21){this.items[t]['colorplanta']='red'}
                if(this.items[t]['resta']>20){this.items[t]['colorplanta']='orange'}
                if(this.items[t]['resta']>60){this.items[t]['colorplanta']='green'}
                this.items[t]['colortexto']='white' 
                }

                if(this.items[t]['agpe']=='CERRADO')
                {
                  this.items[t]['tagpe']="CERRADO";
                  this.items[t]['coloragpe']='black';
                  this.items[t]['colortextoagpe']='white'
                }
                else
                {
                this.items[t]['tagpe']=this.items[t]['agpe']+'  ['+this.items[t]['restap']+']';
                if(this.items[t]['restap']<21){this.items[t]['coloragpe']='red'}
                if(this.items[t]['restap']>20){this.items[t]['coloragpe']='orange'}
                if(this.items[t]['restap']>60){this.items[t]['coloragpe']='green'}
                this.items[t]['colortextoagpe']='white' 
                }

                if(this.items[t]['comercializador']=='CERRADO' || this.items[t]['comercializador']=='NO APLICA')
                {
                  if(this.items[t]['comercializador']=='CERRADO')
                  {
                  this.items[t]['tcomercializador']="CERRADO";
                  }
                  else
                  {
                  this.items[t]['tcomercializador']="NO APLICA";                   
                  }
                  this.items[t]['colorcomercializador']='black';
                  this.items[t]['colortextocomercializador']='white'
                }

                else
                {
                this.items[t]['tcomercializador']=this.items[t]['comercializador']+'  ['+this.items[t]['restap']+']';
                if(this.items[t]['restap']<21){this.items[t]['colorcomercializador']='red'}
                if(this.items[t]['restap']>20){this.items[t]['colorcomercializador']='orange'}
                if(this.items[t]['restap']>60){this.items[t]['colorcomercializador']='green'}
                this.items[t]['colortextocomercializador']='white' 
                }                

              }

              console.log(this.items)
        },
        detalle(datos)
        {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,"proyecto":datos.proyecto};
          axios.post("https://apigestion.coenergia.app/costoproyecto",json,)
          .then(data=>{
            this.valor=0
            this.itemsi=data.data
            for (var t =0 ; t < this.itemsi.length; t++) 
                {
                  let costoun=parseFloat(this.itemsi[t].valor)*parseFloat(this.itemsi[t].cantidad)
                  this.itemsi[t]['valortotal']=costoun
                  this.valor=this.valor+costoun
                }
            this.valor=new Intl.NumberFormat("en-US", {style: "currency",currency: "USD",minimumFractionDigits: 0}).format(this.valor)
            this.proyecto=datos.proyecto
            this.costo=1
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
        },
        detallep(datos)
        {
          if (datos.planta!='CERRADO')
          {
          let posicion=this.arrplanta.indexOf(datos.planta);
          this.seleccion=datos.proyecto
          this.funcion='p'
          this.estado=this.arrplanta[posicion+1]
          this.showmsj=1
          }
        },
        detallea(datos)
        {
          if (datos.agpe!='CERRADO')
          {
          let posicion=this.arragpe.indexOf(datos.agpe);
          this.seleccion=datos.proyecto
          this.funcion='a'
          this.estado=this.arragpe[posicion+1]
          this.showmsj=1
          }
        },
        detallec(datos)
        {
          if (datos.comercializador!='CERRADO')
          {
          let posicion=this.arrcomercializador.indexOf(datos.comercializador);
          this.seleccion=datos.proyecto
          this.funcion='c'
          this.estado=this.arrcomercializador[posicion+1]
          this.showmsj=1
          }
        },
        cambiar()
        {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,"proyecto":this.seleccion,"funcion":this.funcion,"estado":this.estado};
          axios.post("https://apigestion.coenergia.app/cambio",json)
          .then(data=>{
            this.showmsj=0
              this.colores(data.data)
            })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
        },
      }
      }
  </script>

<style>
table {
  border-collapse: separate;
  border:solid black 1px;
  border-radius:6px;
}

thead {
  position: sticky;
  top: 0;
  background-color: black;
}
</style>