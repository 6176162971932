<template>
  <div class="row">
    <Encabezado @refrescar="refrescar"/>
  </div>
    <div class="row">
    </div>
    <div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
    </div>
    <div class="row" style="padding:1% 1% 0% 1%;font-size:70%;">
      <div class="col-3 text-left">
        <div class="row">
          <input type="text" v-model="filtrarinsumo" placeholder="Insumo" class="form-control">
            <ul v-if="buscarinsumo.length" class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10">
              <li v-for="insum in buscarinsumo" :key="insum.insumo" @click="Seleccionarinsumo(insum.insumo)" class="cursor-pointer hover:bg-gray-100 p-1" style="font-size:85%;">{{ insum.insumo }}</li>
            </ul>
        </div>
      </div>
      <div class="col-2">
        <p v-if="inssel" style="margin-top:5%;"><span>{{ inssel }}</span></p>
      </div>
      <div class="col-2">
        <div class="btn-toolbar" style="padding:0% 1% 1% 1%">
      <div class="btn-group mr-1" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-outline-success" @click="consultar()">Articulo</button>
      </div>
      <div class="btn-group mr-2" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-outline-success"  @click="faltantes()">Faltantes</button>
      </div>
    </div>  

      </div> 
      <div class="col-2">
        <input type="number" v-model="cantidad" class="form-control" placeholder="Cantidad">
      </div>
      <div class="col-2">
        <input type="number" v-model="valor" class="form-control" placeholder="Valor">
      </div>                  
      <div class="col-1">
        <div class="btn-toolbar" style="padding:0% 1% 1% 1%">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-outline-success"  @click="mensaje()">Ingresar</button>
      </div>
    </div>  

      </div>                  
    </div><br>
    <div class="row" style="padding:1% 1% 1% 1%">
    <div class="col-6">
      <div style="width: 100%;height: 350px;overflow-y:auto;overflow-x:hidden;">

      <table class="table table-bordered"  style="font-size: 70%">
          <thead style="color:#fff;background-color: gray:  !important;;">
            <tr class="p-0 m-0"><td>Insumo</td><td>Unidad de medida</td><td>Cantidad</td></tr>
          </thead>
          <tbody style="font-size: 80%">
            <tr v-for="itemsj in this.itemsj" :key="itemsj"  @click="solicitar(itemsj.insumo)">
              <td :style="{backgroundColor:itemsj.colorplanta}">{{itemsj.insumo}}</td><td>{{itemsj.udm}}</td><td>{{itemsj.cantidad}}</td>
            </tr>
          </tbody>
      </table>
      </div>
    </div>
    <div class="col-6">
      <div style="width: 100%;height: 350px;overflow-y:auto;overflow-x:hidden;">

      <table class="table table-bordered"  style="font-size: 70%">
          <thead style="color:#fff;background-color: gray:  !important;;">
            <tr class="p-0 m-0"><td>Insumo</td><td>Cantidad</td></tr>
          </thead>
          <tbody style="font-size: 80%">
            <tr v-for="itemsk in this.itemsk" :key="itemsk">
              <td>{{itemsk.proyecto}}</td><td>{{itemsk.cantidad}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>    
    </div>
    <Teleport to="body">
      <modal :show="showmodal" @close="showmodal = false">
        <template #body>
          <h6 class="card-title" style="text-align:center">{{this.msj}}</h6><hr>
          <div class="row mb-1">
                  <div class="col-6"><a @click="showmodal=0" class="btn btn-success">Cancelar</a></div>
                  <div class="col-6"><a @click="agregar()" class="btn btn-success">Aceptar </a></div>
                </div>
        </template>
      </modal>
    </Teleport>
    </template>

  <script>
  require('@/css/sb-admin-2.min.css')
  require('@/vendor/fontawesome-free/css/all.min.css')
  import Encabezado from '@/components/Encabezado.vue';
  import axios from 'axios'
  import CryptoJS from 'crypto-js'
  import Modal from '@/components/Modal.vue'
  import {ref, computed} from 'vue'
  export default 
  {
      name: "HomeView",
      components: {Encabezado,Modal},
      data: function () {
      return {
        proyectos:'',insumos:'',cantidad:null,valor:null,showmodal:0,items:[],itemsi:[],itemsj:[],itemsk:[],msj:''
      };
    },
  setup() {
    let itemsi
    let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,};
              axios.post("https://apigestion.coenergia.app/consultainsumos",json,)
              .then(data=>{itemsi=data.data})
              .catch(error => {console.log(error);this.msj='Error de registro';this.enable=1;})
    let filtrarinsumo = ref('')
    const buscarinsumo = computed(() => {
      if (filtrarinsumo.value === '') {return []}
      let matches = 0
      return itemsi.filter(insum => {
        if (insum.insumo.toLowerCase().includes(filtrarinsumo.value.toLowerCase()) && matches < 10) {
          matches++
          return insum
        }
      })
    });
    const Seleccionarinsumo = (insum) => {
      inssel.value = insum
      filtrarinsumo.value = ''
      let indice = itemsi.findIndex(posicion => posicion.insumo === insum);
      unidad.value=itemsi[indice].udm
      valor.value=itemsi[indice].valor
    }
    let inssel = ref('')
    let unidad = ref('')
    let valor = ref('')
    let listado=ref('')
    return {
      filtrarinsumo,buscarinsumo,Seleccionarinsumo,inssel,unidad,listado
    }
  },
    beforeMount() {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,};
          axios.post("https://apigestion.coenergia.app/pfvactivos",json,)
          .then(data=>{
            this.items=data.data
              axios.post("https://apigestion.coenergia.app/consultainsumos",json,)
              .then(data=>{
                this.itemsi=data.data
                            })
                  .catch(error => {
                        console.log(error);
                        this.msj='Error de registro';
                        this.enable=1;
                        }
                        )
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
    },
    methods: {
      seleccion()
      {
        let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
        let json={"api_token":token,"proyecto":this.proyectos};
          axios.post("https://apigestion.coenergia.app/cargarinsumos",json,)
          .then(data=>{
            this.itemsj=data.data

                            })
                  .catch(error => {
                        console.log(error);
                        this.msj='Error de registro';
                        this.enable=1;
                        }
                        )
        },
        solicitar(insumo)
        {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,"insumo":insumo};
          axios.post("https://apigestion.coenergia.app/detalleinsumos",json,)
          .then(data=>{
            this.itemsk=data.data
            console.log(this.itemsk)
            this.colores(insumo)
            })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
        },        
      mensaje()
      {
        if(this.inssel.length>0 && this.cantidad>0 && this.valor>0)
          {
        this.msj="Desea ingresar " +this.cantidad +" "+this.unidad+" de "+this.inssel+" con valor de "+this.valor
        this.showmodal=1
          }
        else
          {
            alert('falta')
          }
      },
      agregar()
        {
              this.showmodal=0
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"insumo":this.inssel,"cantidad":this.cantidad,"valor":this.valor,"usuario":usuario};
              axios.post("https://apigestion.coenergia.app/agregarinventario",json,)
              .then(data=>{
                this.cantidad=null
                this.insumos=''
                this.valor=null
                this.itemsj=data.data
                            })
                  .catch(error => {
                        console.log(error);
                        this.msj='Error de registro';
                        this.enable=1;
                        }
                        )

        },
        consultar()
        {
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"insumo":this.inssel};
              axios.post("https://apigestion.coenergia.app/consultarinventario",json,)
              .then(data=>{
                this.cantidad=null
                this.insumos=''
                this.itemsj=data.data
                            })
                  .catch(error => {
                        console.log(error);
                        this.msj='Error de registro';
                        this.enable=1;
                        }
                        )

        }, 
        
        faltantes()
        {
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"insumo":this.insumos.insumo};
              axios.post("https://apigestion.coenergia.app/faltantesinventario",json,)
              .then(data=>{
                console.log(data.data)
                this.cantidad=null
                this.insumos=''
                this.itemsj=data.data
                            })
                  .catch(error => {
                        console.log(error);
                        this.msj='Error de registro';
                        this.enable=1;
                        }
                        )

        },         
        stock()
        {
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"insumo":this.insumos.insumo};
              axios.post("https://apigestion.coenergia.app/stockinventario",json,)
              .then(data=>{
                console.log(data.data)
                this.cantidad=null
                this.insumos=''
                this.itemsj=data.data
                            })
                  .catch(error => {
                        console.log(error);
                        this.msj='Error de registro';
                        this.enable=1;
                        }
                        )

        }, 

        refrescar(ruta)
        {
          this.$router.push(ruta);
        },
        colores(proyec)
        {
          for (var t =0 ; t < this.itemsj.length; t++) 
            {
            if(this.itemsj[t]['insumo']==proyec){this.itemsj[t]['colorplanta']='red'}else{this.itemsj[t]['colorplanta']='#CBC8C7'}
            }
        },
 
      }
      }
  </script>

<style>
table {
  border-collapse: separate;
  border:solid black 1px;
  border-radius:6px;
}

thead {
  position: sticky;
  top: 0;
  background-color: black;
}
</style>