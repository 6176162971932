<template>
  <div class="row">
    <Encabezado @refrescar="refrescar"/>
  </div>
    <div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
    </div>
    <div class="row" style="padding:2% 2% 0% 0%;font-size:80%;">
      <div class="col-2 text-left"><label @click="this.proyecto=''">CLIENTE</LABEL></div>
      <div class="col-4 text-left">
          <select class="form-control" v-model="proyecto" @change="seleccion()">
            <option v-for="item in this.items" :key="item" :value="item.proyecto">{{ item.proyecto }}</option>
          </select>
      </div>
      <div class="col-2 text-left "><label  @click="this.proveedor=''">PROVEED</LABEL></div>
      <div class="col-4 text-left">
          <select class="form-control" v-model="proveedor">
            <option v-for="itemj in this.itemsj" :key="itemj" :value="itemj.proveedor">{{ itemj.proveedor }}</option>
          </select>
      </div>
    </div>
    <div class="row" style="padding:1% 1% 0% 0%;font-size:80%;">
      <div class="col-2 text-left "><label  @click="this.servicio=''">SERVICIO</LABEL></div>
      <div class="col-4 text-left">
          <select class="form-control" v-model="servicio">
            <option v-for="itemi in this.itemsi" :key="itemi" :value="itemi.servicio">{{ itemi.servicio }}</option>
          </select>
      </div>
      <div class="col-3">
        <input type="number" v-model="valor" class="form-control" placeholder="valor">
      </div>
      <div class="col-2">
        <button class="btn btn-success" @click="mensaje()">Agregar</button>
      </div>
    </div><hr>
    <div class="row" style="padding:1% 1% 0% 0%;font-size:80%;">
      <div class="col-2 text-left "><label>RANGO</LABEL></div>
      <div class="col-4 text-left">
        <input style="font-size:85%;" type="date" v-model="inicio" class="form-control" placeholder="valor">
      </div>
      <div class="col-4 text-left">
        <input style="font-size:85%;" type="date" v-model="fin" class="form-control" placeholder="valor">
      </div>
      <div class="col-2">
        <button class="btn btn-success" @click="cservicio()"><i class="fas fa-fw fa-search" style="font-size: 1.0rem; color: white;"></i></button>
      </div>                          
    </div>                
    <div class="row" style="padding:1% 1% 1% 1%">
    <div class="col-12">
      <div style="width: 100%;height: 350px;overflow-y:auto;overflow-x:hidden;">
      <table class="table table-bordered"  style="font-size: 80%">
          <thead style="color:#fff;background-color: gray:  !important;;">
            <tr class="p-0 m-0"><td>Cliente</td><td>Servicio</td><td>Proveedor</td><td>Costo</td><td>Fecha</td></tr>
          </thead>
          <tbody style="font-size: 80%">
            <tr v-for="itemsk in this.itemsk" :key="itemsk" @click="recibir(itemsk)">
              <td>{{itemsk.proyecto}}</td><td>{{itemsk.insumo}}</td><td>{{itemsk.udm}}</td><td>{{itemsk.valor}}</td><td>{{itemsk.campo_recibido}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
    <Teleport to="body">
      <modal :show="showmodal" @close="showmodal = false">
        <template #body>
          <h5 class="card-title" style="text-align:center">{{this.msj}}</h5><br>
         <div class="row mb-1">
                  <div class="col-6" style="font-size: 100%"><a @click="showmodal=0" class="btn btn-success">Cancelar</a></div>
                  <div class="col-6" style="font-size: 100%"><a @click="enviar()" class="btn btn-success">Aceptar </a></div>
                </div>
        </template>
      </modal>
    </Teleport>
    </template>

  <script>
  require('@/css/sb-admin-2.min.css')
  require('@/vendor/fontawesome-free/css/all.min.css')
  import Encabezado from '@/components/Encabezado.vue';
  import axios from 'axios'
  import CryptoJS from 'crypto-js'
  import Modal from '@/components/Modal.vue'
  export default 
  {
      name: "HomeView",
      components: {Encabezado,Modal},
      data: function () {
      return {
        showmodal:0,items:[],itemsi:[],itemsj:[],itemsk:[],msj:'',linea:[],proyecto:'',servicio:'',proveedor:'',valor:null,inicio:null,fin:null
      };
    },
    beforeMount() {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,};
          axios.post("https://apigestion.coenergia.app/pfvactivos",json,)
          .then(data=>{
            this.items=data.data
              axios.post("https://apigestion.coenergia.app/listarservicio",json,)
              .then(data=>{
                console.log(data.data)
                this.itemsi=data.data
                    axios.post("https://apigestion.coenergia.app/listarproveedor",json,)
                  .then(data=>{
                    console.log(data.data)
                    this.itemsj=data.data
                                })
                      .catch(error => {
                            console.log(error);
                            this.msj='Error de registro';
                            this.enable=1;
                            }
                            )
                            })
                  .catch(error => {
                        console.log(error);
                        this.msj='Error de registro';
                        this.enable=1;
                        }
                        )
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
    },
    methods: {
      mensaje()
      {
        if(this.proyecto.length>0 && this.servicio.length>0 && this.proveedor.length>0 && this.valor>0)
          {
        this.msj="Desea agregar " +this.servicio +" de "+this.proveedor+" para "+this.proyecto
        this.showmodal=1
          }
        else
          {
            alert('falta')
          }
      },
        recibir(detalle)
        {
          this.qty=0
          this.linea=detalle
          this.msj='Indique la cantidad recibida de ' +this.linea.insumo
          this.showmodal=1
        },
        enviar()
        {
              console.log(this.linea)
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"usuario":usuario,"proyecto":this.proyecto,"servicio":this.servicio,"proveedor":this.proveedor,"valor":this.valor};
              axios.post("https://apigestion.coenergia.app/recibirservicio",json,)
              .then(data=>{
                this.itemsk=data.data
                this.proyecto=''
                this.proveedor=''
                this.servicio=''
                this.valor=''
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
          this.showmodal=0
        },
        seleccion()
        {
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"usuario":usuario,"proyecto":this.proyecto};
              axios.post("https://apigestion.coenergia.app/consultarserviciocliente",json,)
              .then(data=>{
                this.itemsk=data.data
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
          this.showmodal=0
        },                           
        refrescar(ruta)
        {
          this.$router.push(ruta);
        },
        cservicio()
        {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"usuario":usuario,"proyecto":this.proyecto,'proveedor':this.proveedor,"servicio":this.servicio,"inicio":this.inicio,"fin":this.fin};
              axios.post("https://apigestion.coenergia.app/consultarservicio",json,)
              .then(data=>{
                console.log(data.data)
                this.itemsk=data.data
                
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
          this.showmodal=0
        },        

 
      }
      }
  </script>

<style>
table {
  border-collapse: separate;
  border:solid black 1px;
  border-radius:6px;
}

thead {
  position: sticky;
  top: 0;
  background-color: black;
}
</style>